import { BASE_URL } from "../App"
import Address from "../components/Contact/Address"
import ContactForm from "../components/Contact/ContactForm"
import { redirect } from 'react-router-dom'

const ContactPage = () => {
    return (
        <>
            <ContactForm />
            <Address />
        </>
    )
}

export default ContactPage

export async function action({ request }) {
    const data = await request.formData()

    const visitorMessageData = {
        name: data.get('name'),
        phone: data.get('phone')
    }

    if(data.get('email') !== '' && data.get('email') !== undefined) {
        visitorMessageData.email = data.get('email')
    }

    if(data.get('message') !== '' && data.get('message') !== undefined) {
        visitorMessageData.message = data.get('message')
    }

    const response = await fetch(BASE_URL+'/messages', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(visitorMessageData)
    })

    if(!response.ok) {
        throw response
    }

    return redirect('/')
}