import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const ContactUs = () => {
    return (
        <Card style={{ width: '100vw' }}>
            <Card.Body style={{ padding: '2rem' }}>
                <Container>
                    <Row>
                        <Col>
                            <h1 className="text-center">We can increase your sales!</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center pt-3">
                            <Button variant="dark">Contact Us</Button>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    )
}

export default ContactUs