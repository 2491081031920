import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card'

import { Link } from 'react-router-dom'

const Address = () => {
    return (
        <Card style={{ width: '100vw', padding: '1rem' }}>
            <Card.Body>
                <Container>
                    <Row>
                        <Col>
                            <h1 className="text-center">Office Address</h1>
                        </Col>
                    </Row>
                    <Row className='pt-4'>
                        <Col>
                            <p>
                                ADDRESS : GHB LIG, 150 feet, 2nd Ring Rd, Rajkot, Gujarat 360005.
                            </p>
                            <p>
                                PHONE : +91-9426429963
                            </p>
                            <p>
                                EMAIL :  <Link to="#">contact@tusharpandya.com</Link> / <Link to="#">ptooshar@gmail.com</Link>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    )
}

export default Address