import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { useRef } from 'react';
import { useSubmit } from 'react-router-dom'

const ContactForm = () => {
    const submit = useSubmit()
    const nameRef = useRef()
    const emailRef = useRef()
    const phoneRef = useRef()
    const messageRef = useRef()

    const submitFormHandler = (e) => {
        e.preventDefault()

        const formData = {
            name: nameRef.current.value,
            phone: phoneRef.current.value,
            email: emailRef.current.value,
            message: messageRef.current.value
        }
        
        submit(formData, {method: 'POST'})
    }

    return (
        <Card style={{ width: '90vw', padding: '1rem', margin: '8rem 4rem 4rem ' }}>
            <Card.Body>
                <Container>
                    <h1 className='text-center'>Let's Talk</h1>
                    <Form className='mt-3'>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter name" ref={nameRef} autoComplete='off'/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" ref={emailRef} autoComplete='off'/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicNumber">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="string" placeholder="Enter phone number" ref={phoneRef} autoComplete='off'/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Comments / Questions</Form.Label>
                            <Form.Control as="textarea" rows={5} ref={messageRef} autoComplete='off' />
                        </Form.Group>
                        <Button variant="primary" type="submit" onClick={submitFormHandler}>
                            Send Message
                        </Button>
                    </Form>
                </Container>
            </Card.Body>
        </Card>
    )
}

export default ContactForm