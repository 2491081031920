import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import { useLoaderData } from 'react-router-dom'

const convertToImage = ({ logo }) => {
    const base64String = logo
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);
    const blob = new Blob([byteArray], { type: 'image/png' });
    const imageUrl = URL.createObjectURL(blob);

    return imageUrl
}

const OurClients = () => {
    const { prevClients } = useLoaderData()

    const clientImages = prevClients.reduce((acc, client, index) => {
        if (index % 3 === 0) {
            acc.push([]);
        }
        const clientImage = convertToImage(client);
        acc[acc.length - 1].push(clientImage);
        return acc;
    }, []);

    return (
        <Card style={{ width: '100vw', padding: '1rem' }}>
            <Card.Body>
                <Container>
                    <Row>
                        <Col>
                            <h1 className='text-center'>Our Clients</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Carousel data-bs-theme="dark" controls={null} style={{ marginTop: '4rem' }}>
                                {
                                    clientImages.map((img) => {
                                        return (
                                            <Carousel.Item className='pb-5'>
                                                <Container
                                                    className='d-flex align-items-center justify-content-between w-100'
                                                >
                                                    {img.map((imageUrl) => {
                                                        return <img src={imageUrl} alt='company logo' />
                                                    })}
                                                </Container>
                                            </Carousel.Item>
                                        )

                                    })
                                }
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    )
}

export default OurClients