import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { Link } from 'react-router-dom'

const Connect = () => {
    return (
        <Card style={{ width: '100vw' }}>
            <Card.Body style={{ padding: '2rem'}}>
                <Container>
                    <Row>
                        <Col>
                            <h1 className="text-center">Let's Connect</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="text-center">
                                <Link to="#">ptooshar@gmail.com</Link>
                            </h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-center'>
                            <Link to="#" style={{ color: 'black', textDecoration: 'none', padding: '0.6rem', fontSize: '1.5rem' }}>
                                <i class="fa fa-facebook-square" aria-hidden="true"></i>
                            </Link>
                            <Link to="#" style={{ color: 'black', textDecoration: 'none', padding: '0.6rem', fontSize: '1.5rem' }}>
                                <i class="fa fa-linkedin" aria-hidden="true"></i>
                            </Link>
                            <Link to="#" style={{ color: 'black', textDecoration: 'none', padding: '0.6rem', fontSize: '1.5rem' }}>
                                <i class="fab fa-twitter"></i>
                            </Link>
                            <Link to="#" style={{ color: 'black', textDecoration: 'none', padding: '0.6rem', fontSize: '1.5rem' }}>
                                <i class="fab fa-instagram"></i>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    )
}

export default Connect