import SloganCarousal from "../components/Home/SloganCarousal"
import About from "../components/Home/About"
import Connect from "../components/Home/Connect"

const HomePage = () => {
    return (
        <main>
            <SloganCarousal />
            <About />
            <Connect />
        </main>
    )
}

export default HomePage