import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Speciality = () => {
    return (
        <Carousel data-bs-theme="dark" controls={null} style={{ marginTop: '4rem'}}>
            <Carousel.Item>
                <Container
                    className='d-flex align-items-center justify-content-center w-100'
                    style={
                        { height: '20rem' }
                    }
                >
                    <Row>
                        <Col>
                            <h1 className="text-center"
                                style={{ fontSize: '2.2rem', fontWeight: '600', padding: '1.5rem 0'}}>
                                Social Media Marketing
                            </h1>
                            <p className="text-center"
                                style={{ fontSize: '1.2rem', fontWeight: '400' }}
                            >
                                FACEBOOK: Content create & Post, Page/Community Create & Post, Page Management <br />
                                Twitter: Content create & Post, MY BUSINESS: Posting, Update <br />
                                LINKEDIN: Content create & Post
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Carousel.Item>
            <Carousel.Item>
            <Container
                    className='d-flex align-items-center justify-content-center w-100'
                    style={
                        { height: '20rem' }
                    }
                >
                    <Row>
                        <Col>
                            <h1 className="text-center"
                                style={{ fontSize: '2.2rem', fontWeight: '600', padding: '1.5rem 0'}}>
                                B2B Posting and account Management
                            </h1>
                            <p className="text-center"
                                style={{ fontSize: '1.2rem', fontWeight: '400' }}
                            >
                                ALIBABA & TRADEINDIA: Product Posting, Product Update, Account Management <br />
                                INDIAMART: Product Posting, Category Mapping <br />
                                NOWFLOATS & ADVERTROINDIA: Fresh Content, Daily Update, All keywords and All city cover Posting
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Carousel.Item>
            <Carousel.Item>
            <Container
                    className='d-flex align-items-center justify-content-center w-100'
                    style={
                        { height: '20rem' }
                    }
                >
                    <Row>
                        <Col>
                            <h1 className="text-center"
                                style={{ fontSize: '2.2rem', fontWeight: '600', padding: '1.5rem 0'}}>
                                Blogging
                            </h1>
                            <p className="text-center"
                                style={{ fontSize: '1.2rem', fontWeight: '400' }}
                            >
                                BLOG: Create & Post creative contents related to your product as well as your company
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Carousel.Item>
            <Carousel.Item>
            <Container
                    className='d-flex align-items-center justify-content-center w-100'
                    style={
                        { height: '20rem' }
                    }
                >
                    <Row>
                        <Col>
                            <h1 className="text-center"
                                style={{ fontSize: '2.2rem', fontWeight: '600', padding: '1.5rem 0'}}>
                                YouTube promotion
                            </h1>
                            <p className="text-center"
                                style={{ fontSize: '1.2rem', fontWeight: '400' }}
                            >
                                Promote your channel/ videos through social media, whatsapp and other online portals
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Carousel.Item>
            <Carousel.Item>
            <Container
                    className='d-flex align-items-center justify-content-center w-100'
                    style={
                        { height: '20rem' }
                    }
                >
                    <Row>
                        <Col>
                            <h1 className="text-center"
                                style={{ fontSize: '2.2rem', fontWeight: '600', padding: '1.5rem 0'}}>
                                Image/Brand Development
                            </h1>
                            <p className="text-center"
                                style={{ fontSize: '1.2rem', fontWeight: '400' }}
                            >
                                Develop your brand and suggest a bunch of online promotions after analyzing your online activity and memberships 
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Carousel.Item>
            <Carousel.Item>
            <Container
                    className='d-flex align-items-center justify-content-center w-100'
                    style={
                        { height: '20rem' }
                    }
                >
                    <Row>
                        <Col>
                            <h1 className="text-center"
                                style={{ fontSize: '2.2rem', fontWeight: '600', padding: '1.5rem 0'}}>
                                Directory Listing
                            </h1>
                            <p className="text-center"
                                style={{ fontSize: '1.2rem', fontWeight: '400' }}
                            >
                                Product/Profile posting at more than 400 b2b sites
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Carousel.Item>
            <Carousel.Item>
            <Container
                    className='d-flex align-items-center justify-content-center w-100'
                    style={
                        { height: '20rem' }
                    }
                >
                    <Row>
                        <Col>
                            <h1 className="text-center"
                                style={{ fontSize: '2.2rem', fontWeight: '600', padding: '1.5rem 0'}}>
                                Email Marketing
                            </h1>
                            <p className="text-center"
                                style={{ fontSize: '1.2rem', fontWeight: '400' }}
                            >
                                100+ emails daily
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Carousel.Item>
            <Carousel.Item>
            <Container
                    className='d-flex align-items-center justify-content-center w-100'
                    style={
                        { height: '20rem' }
                    }
                >
                    <Row>
                        <Col>
                            <h1 className="text-center"
                                style={{ fontSize: '2.2rem', fontWeight: '600', padding: '1.5rem 0'}}>
                                Creative Writing
                            </h1>
                            <p className="text-center"
                                style={{ fontSize: '1.2rem', fontWeight: '400' }}
                            >
                                Rs.5/- per word, English/Hindi, Min. 500 words
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Carousel.Item>
        </Carousel>
    )
}

export default Speciality