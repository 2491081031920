import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { Link } from 'react-router-dom'

const Navigation = () => {
    return (
        <Navbar expand="lg" className="bg-body-tertiary fixed-top">
            <Container>
                <Navbar.Brand style={{ fontWeight: '800', fontSize: '1.5rem'}}>TUSHAR PANDYA</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
                    <Nav className="me-2">
                        <Nav.Link as={Link} to=''>Home</Nav.Link>
                        <Nav.Link as={Link} to="services">Services</Nav.Link>
                        <Nav.Link as={Link} to="blog">Blog</Nav.Link>
                        <Nav.Link as={Link} to="contact">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navigation