import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import HomePage from './pages/Home';
import ServicesPage, { loader as prevClientsLoader } from './pages/Services';
import BlogPage, { loader as postsLoader} from './pages/Blog';
import ContactPage, { action as contactAction } from './pages/Contact';
import RootLayout from './pages/Root';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <RootLayout />,
      children: [
        { index: true, element: <HomePage /> },
        { 
          path: 'services', 
          element: <ServicesPage />,
          loader: prevClientsLoader
        },
        { path: 'blog', element: <BlogPage />, id: 'posts',  loader: postsLoader},
        { path: 'contact', element: <ContactPage />, action: contactAction }
      ]
    }
  ])
  return (
    <RouterProvider router={router}></RouterProvider>
  );
}

export default App;

export const BASE_URL = "https://tusharpandya-backend.onrender.com"
