import { BASE_URL } from "../App"
import BlogPosts from "../components/Blog/BlogPosts"

const BlogPage = () => {
    return <BlogPosts />
}

export default BlogPage

export const loader = async () => {

    const response = await fetch(BASE_URL+'/posts', {
        method: 'GET'
    })

    if (!response.ok) {
        throw response
    }

    return response
}