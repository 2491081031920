import ContactUs from '../components/Services/ContactUs'
import FAQ from '../components/Services/FAQ'
import OurServices from '../components/Services/OurServices'
import OurSpeciality from "../components/Services/OurSpeciality"
import OurClients from '../components/Services/OurClients'

import { json } from 'react-router-dom'
import { BASE_URL } from '../App'

const ServicesPage = () => {
    return (
        <>
            <OurServices />
            <OurSpeciality />
            <OurClients />
            <ContactUs />
            <FAQ />
        </>
    )
}

export default ServicesPage

export async function loader() {
    const response = await fetch(BASE_URL+'/prevClients')

    if(!response.ok) {
        throw json({ message: 'Could not fetch clients.'}, { status: 500} )
    } else {
        return response
    }
}