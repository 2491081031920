import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <p className="text-center" style={{ padding: '1rem 0'}}>
            <Link to="#">@2020 tusharpandya.com All Rights Reserved</Link> | site visit: <Link to="#">tusharpandya.com</Link> | call : 91-9426429963
        </p>
    )
}

export default Footer