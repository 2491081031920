import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const OurSpeciality = () => {
    return (
        <Card style={{ width: '100vw', margin: '3rem 0 0 0', padding: '1rem'}}>
            <Card.Body>
                <Container>
                    <Row>
                        <Col>
                            <h1 className="text-center">Digital Marketing</h1>
                        </Col>
                    </Row>
                    <Row className='pt-4'>
                        <Col>
                            <p>
                                Product promotion is one of the necessities for getting your brand in front of the public and attracting new customers. There are numerous ways to promote a product or brand. Some companies use more than one method, while others may use different methods for different marketing purposes. Regardless of your company’s product or service, a strong set of promotional strategies can help position your company in a favorable light with not only current customers but new ones as well.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    )
}

export default OurSpeciality