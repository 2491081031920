import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const BlogPostItem = (props) => {
    const postDescription = props.postData.description
    console.log(postDescription)
    return (
        <Card style={{ width: '90vw', padding: '1rem'}} className='mb-3'>
            <Card.Body>
                <Container>
                    <Row>
                        <Col>
                            <h1>{props.postData.title}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p style={{ whiteSpace: 'pre-line' }}>{postDescription}</p>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    )
}

export default BlogPostItem