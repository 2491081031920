import { useRouteLoaderData } from "react-router-dom"
import BlogPostItem from "./BlogPostItem"
import Container from 'react-bootstrap/Container'

const BlogPosts = () => {
    const { blogPosts } = useRouteLoaderData('posts')
    console.log(blogPosts)
    return (
        <Container style={{ margin: '8rem 4rem 4rem ' }}>
            { blogPosts.map((post, index) => {
                return <BlogPostItem key={index} postData={post} />
            })}
        </Container>
    )
}

export default BlogPosts