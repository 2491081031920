import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const About = () => {
    return (
        <Card style={{ width: '100vw', margin: '3rem 0 0 0', padding: '1rem' }}>
            <Card.Body>
                <Container>
                    <Row>
                        <Col>
                            <h1 className="text-center">About Me</h1>
                        </Col>
                    </Row>
                    <Row className='pt-4'>
                        <Col>
                            <h4 className="text-center">
                                Influencer | Digital Marketing Expert | Freelancer | Contact for marketing promotions
                            </h4>
                        </Col>
                    </Row>
                    <Row className='pt-4'>
                        <Col>
                            <p>
                                Internet digital marketing consultant, author with over 1.5 decades of experience in online marketing, blog development, B2B marketing, Social Media Marketing, content posting and membership site development.
                            </p>
                            <p>
                                Business owners across the globe have profited from my 15 years digital marketing experience to grow their profits and reduce the amount of time they spend working directly in their business.
                            </p>
                            <p>
                                I help successful business owners reach the next level.
                            </p>
                            <p>
                                If you are an established business owner who wants to increase your earning power while decreasing your daily workload, please contact.
                            </p>
                            <p>
                                When I have an opening, I love to work with motivated business owners who are ready to add another zero or two to their yearly net.
                            </p>
                            <p>
                                Contact for product support or to inquire about consulting opportunities.
                            </p>
                            <p>
                                I am delighted to welcome you to one of the leading steps for Internet marketing. Having experience and expertise in providing complete online solutions which includes internet marketing, B2B Marketing, Social Media marketing, Blogging, Review and comment posting.
                            </p>
                            <p>
                                At present we are serving more than 40 companies.
                            </p>
                            <p>
                                Strongly believe in quality and commitment. Hence, assure you that I shall continuously strive to deliver you options for world class product promotion.
                            </p>
                            <p>
                                Client satisfaction and success is everything to us.
                            </p>
                            <p>
                                Our web related services meet international standards thus we are focused toward offering best solutions for clients in India and abroad.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    )
}

export default About