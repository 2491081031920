import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';

const SloganCarousal = () => {
    return (
        <Carousel data-bs-theme="dark" controls={null} style={{ marginTop: '4rem'}}>
            <Carousel.Item>
                <Container
                    className='d-flex align-items-center justify-content-center w-100'
                    style={
                        { height: '20rem', fontSize: '3rem', lineHeight: '4rem', fontWeight: '900' }
                    }
                >
                    <p className="text-center">
                        Working with a vision, plan and purpose <br />
                        to make a difference...
                    </p>

                </Container>
            </Carousel.Item>
            <Carousel.Item>
                <Container
                    className='d-flex align-items-center justify-content-center w-100'
                    style={{ 'height': '20rem', fontSize: '3rem', lineHeight: '4rem', fontWeight: '900'}}
                >
                    <p className="text-center">
                        India's first Freelance Digital Media Expert <br />
                        Since 2005
                    </p>
                </Container>
            </Carousel.Item>
        </Carousel>
    )
}

export default SloganCarousal