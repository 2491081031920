import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const FAQ = () => {
    return (
        <Card style={{ width: '100vw' }}>
            <Card.Body style={{ padding: '2rem' }}>
                <Container>
                    <Row>
                        <Col>
                            <h1 className="text-center">Frequently Asked Questions</h1>
                        </Col>
                    </Row>
                    <Row className='pt-4'>
                        <Col>
                            <p>
                                <strong >+</strong>  What is Product Promotion?
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                <strong >+</strong>  What are the types of portals required for business and what are required in them?
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                <strong >+</strong>  Which B2B portal membership is required?
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                <strong >+</strong>  What is the difference between B2B and B2C?
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                <strong >+</strong>  Promotion can be done without taking paid membership?
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    )
}

export default FAQ